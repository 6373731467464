import { useCheckoutContext } from 'contexts/checkout'
import { packageContainsLGSR } from 'utils/packageContainsLGSR'
import DatePicker from 'components/datePicker'
import { isCTMHost } from 'utils/partners'
import { useEffect, useState } from 'react'
import { calculateNextASVDate } from 'api/calculateNextASVDate'
import { format, parseISO } from 'date-fns'

function StartDate(): JSX.Element {
    const {
        state: { date, packageDetails, packageId },
        dispatch,
    } = useCheckoutContext()

    const [calculatedASVDate, setCalculatedASVDate] = useState<string>('')

    const onChange = (newDate: Date) => {
        dispatch({ type: 'setCheckout', data: { date: newDate } })
    }

    useEffect(() => {
        setCalculatedASVDate('')

        calculateNextASVDate(date, packageId).then((dateString) => {
            const parsedDate = parseISO(`${dateString}-01`) // Adding "-01" to ensure it's parsed as the first day of the month
            const formattedDate = format(parsedDate, 'MMMM yyyy')
            setCalculatedASVDate(formattedDate)
        })
    }, [date])

    return (
        <>
            <DatePicker
                id="HomeCareCover_Checkout_Payment_ChangeStartDate"
                dataTestId="datepicker"
                header={isCTMHost() ? 'Care plan start date' : 'Cover plan start date'}
                onChange={onChange}
                value={date}
                minDate={new Date()}
            />
            {!packageContainsLGSR(packageDetails) && (
                <>
                    {calculatedASVDate ? (
                        <p>
                            {isCTMHost() ? (
                                <>
                                    Your boiler service will be scheduled for:{' '}
                                    <strong>{calculatedASVDate}</strong>. You can change this after
                                    checkout.
                                </>
                            ) : (
                                <>
                                    We&apos;ve scheduled your boiler service for{' '}
                                    <strong>{calculatedASVDate}</strong>. You can reschedule to
                                    another spring or summer month after checkout, as this helps us
                                    prioritise supporting customers with claims during winter.
                                </>
                            )}
                        </p>
                    ) : (
                        <p>Calculating your next boiler service date...</p>
                    )}
                </>
            )}
        </>
    )
}

export default StartDate
