import { generateGcSuccessUrl } from 'utils/generateGcSuccessUrl'
import { isCTMHost } from 'utils/partners'
import { BillingType } from '../../types/formData'
import { CheckoutState } from './type'

export const DEFAULT_PRICEBOOK = isCTMHost() ? 51 : 51

export const generateDefaultState = (): CheckoutState => ({
    accountId: 123,
    author: 'website',
    billingType: BillingType.MONTHLY,
    contractCreatedDate: '',
    customerPrefsHash: '',
    contribution: 0,
    street: '',
    town: '',
    county: '',
    postcode: '',
    country: 'United Kingdom',
    date: new Date(),
    gcDescription:
        'Just 30 seconds more….enter your details below and we’ll have you up and running in no time.',
    gcSuccessRedirect: generateGcSuccessUrl(window.location.hostname),
    gcSessionToken: new Date().getTime().toString(),
    marketingEmail: false,
    marketingPhone: false,
    marketingSms: false,
    marketingOptIn: false,
    monthlyDiscount: 0,
    monthlyTotalPrice: 0,
    packageType: 'homeowner',
    phonePrefix: '44',
    referralProgramme: false,
    pricebookId: DEFAULT_PRICEBOOK,
    packageId: 13,
    landingPage: '/',
    promoCode: {},
    packageName: 'Your Boiler',
    nextServiceDate: '',
    whyChooseUs: [],
    newProductsSuggestions: [],
    registerCustomerRequest: {
        isRequesting: false,
        isError: false,
    },
    salesAgent: '',
    referralLink: '',
})
